import { Form, Formik } from "formik";
import { phoneRegex } from "utils";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import useUpdateInstitution from "api/InstitutionResource/useUpdateInstitution";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { countries, institutionLevels } from "utils/data";
import useSearchParams from "hooks/useSearchParams";
import useGetProvinces from "api/Province/useGetProvinces";

const UpdateInstitution = ({
	institutionId,
	name,
	level,
	address,
	country,
	contactNumber,
	province,
}) => {
	const { getParam } = useSearchParams();

	const returnUrl = getParam("returnUrl") || "";

	const { data: provinces, isLoading: isLoadingProvinces } = useGetProvinces();

	const initialValues = {
		name: name || "",
		level: level || "",
		address: address || "",
		country: country || "",
		contact_number: contactNumber || "",
		province: province || "",
	};

	const validate = Yup.object().shape({
		name: Yup.string().required("Name of institution is required"),
		country: Yup.string().required("Country is required"),
		province: Yup.string().when("country", {
			is: (val) => val === "Zimbabwe",
			then: Yup.string().required("Province is required"),
		}),
		contact_number: Yup.string()
			.required("Contact number is required")
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
	});

	const { mutateAsync, isLoading } = useUpdateInstitution(
		institutionId,
		returnUrl
	);

	const handleSave = async (data) => {
		await mutateAsync(data);
	};

	return (
		<>
			<div>
				<p className="text-base">
					Update details for <span className="font-bold">{name}</span>
				</p>
				<p>Fields marked (*) are required</p>

				<div className="mt-4 pt-4">
					<Formik
						initialValues={initialValues}
						validationSchema={validate}
						onSubmit={(data) => handleSave(data)}
					>
						{({ setFieldValue, values, touched, errors }) => (
							<Form className="space-y-8">
								<FormField
									name="name"
									label="Name of Institution *"
								/>

								<FormField
									type="select"
									name="country"
									label="Country *"
									options={countries.map((country) => ({
										label: country.name,
										value: country.name,
									}))}
								/>

								<div
									className={`relative ${
										values.country === "Zimbabwe" ? "" : "hidden"
									}`}
								>
									<AiOutlineLoading3Quarters
										size={20}
										className={`${
											isLoadingProvinces ? "animate-spin" : "hidden"
										} absolute top-2 right-14 z-10`}
									/>
									<FormField
										type="select"
										name="province"
										label="Province *"
										isLoading={isLoadingProvinces}
										options={provinces?.map((province) => ({
											label: province.name,
											value: province.name,
										}))}
									/>
								</div>

								<FormField
									name="contact_number"
									type="phone"
									label="Contact Number *"
									placeholder="contact_number"
									value={values.contact_number}
									_onChange={(phone) => {
										if (phone !== "+") values.contact_number = "+" + phone;
										else values.contact_number = phone;
									}}
									error={Boolean(
										errors.contact_number && touched.contact_number
									)}
									errorText={errors.contact_number}
								/>

								<FormField
									name="address"
									label="Physical Address"
								/>

								<FormField
									type="select"
									name="level"
									label="Institution Level *"
									options={institutionLevels.map((level) => ({
										label: level.name,
										value: level.value,
									}))}
								/>

								<div className="mt-4 flex items-center">
									<button
										type="submit"
										className="py-3 px-3 bg-akgreen w-full rounded-full text-white flex items-center justify-center gap-2 max-w-max"
									>
										Update details
										{isLoading && (
											<AiOutlineLoading3Quarters
												size={20}
												className="animate-spin"
											/>
										)}
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
};

export default UpdateInstitution;

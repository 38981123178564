import Axios from "axios";

/**
 * @typedef {Object} token
 * @property {string} accessToken
 *
 * @param {token} token
 * @param {string} contentType
 */
const InitApi = async (token, contentType = "application/json") => {
	const { accessToken } = token;

	let headers = {
		Accept: "application/json",
		"Content-Type": contentType,
	};

	// 1000000 ms = 1000 s = 1 min

	const instance = Axios.create({
		baseURL: process.env.REACT_APP_API_BASE_URL,
		timeout: 1000000,
		withCredentials: false,
		headers: {
			...headers,
			Authorization: `Bearer ${accessToken}`,
		},
	});

	return instance;
};

export default InitApi;

import useChangeStatus from "api/PublisherResource/useChangeStatus";
import { SplitButton } from "primereact/splitbutton";
import { PublisherApprovalStatus } from "utils/constants";

const PublisherStatus = ({ status, publisherId }) => {
	const { mutate, isLoading } = useChangeStatus(publisherId);

	const items = [
		{
			label: "Change to 'Approved' ",
			command: () => mutate(PublisherApprovalStatus.Approved),
		},
		{
			label: "Change to 'Rejected' ",
			command: () => mutate(PublisherApprovalStatus.Rejected),
		},
		{
			label: "Change to 'Pending' ",
			command: () => mutate(PublisherApprovalStatus.Pending),
		},
	];

	return (
		<div className="publisher-status">
			<SplitButton
				className=" w-32 h-7 bg-akgreener text-white rounded-full border-none"
				label={status}
				dropdownIcon={
					isLoading ? "pi pi-spin pi-spinner" : "pi pi-chevron-down"
				}
				model={items}
			/>
		</div>
	);
};

export default PublisherStatus;

import Admin2 from "layouts/Admin2";
import { useState } from "react";
import { DataTable } from "primereact/datatable";
import useFetchUsers from "api/UserResource/useFetchUsers";
import { Column } from "primereact/column";
import EditUser from "admin/Users2/EditUser";
import DeleteUser from "admin/Users2/DeleteUser";
import { useFormik } from "formik";
import useBulkActionUsers from "api/UserResource/useBulkActionUsers";
import { AiOutlineLine, AiOutlineLoading3Quarters } from "react-icons/ai";
import useFetchPermissions from "api/RoleResource/useFetchPermissions";
import UserLogins from "./UserLogins";
import useAuthStore from "store/authStore";
import useProfile from "api/UserResource/useProfile";
import AdminPageLoader from "components/AdminPageLoader";
import useUsersFilters from "./useUsersFilters";
import UsersFilters from "./UsersFilters";
import { CustomPaginator } from "components";
import { Role } from "utils/roles";

const Users2 = () => {
	const filters = useUsersFilters();

	const { hasRoles } = useAuthStore();

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	const userProfile = profile?.data;

	const publisherIs = hasRoles([Role.SENIOR_PUBLISHER])
		? userProfile?.publishers[0]?.id
		: "";
	const institutionIs = hasRoles([Role.INSTITUTION, Role.EDUCATOR])
		? userProfile?.institutions[0]?.id
		: "";

	const { data: usersData, isLoading: isLoadingUsers } = useFetchUsers({
		page: filters?.page,
		rows_per_page: filters?.rowsPerPage,
		date_from: filters?.dateFrom,
		date_to: filters?.dateTo,
		search: filters?.search,
		"filter[trashed]": filters?.trashed,
		"filter[role_is]": filters?.roleIs,
		"filter[publisher_is]": publisherIs,
		"filter[institution_is]": institutionIs,
	});

	const { data: allPermissions, isLoading: isLoadingPermissions } =
		useFetchPermissions();

	const users = usersData?.data?.records;

	const totalRecords = usersData?.data?.total_records;

	const actionBodyTemplate = (row) => (
		<div className="flex flex-row gap-1 items-center text-akgreen">
			{/* <UserLogins logins={row?.logins} /> */}

			{row?.deleted_at === null && (
				<>
					<span className="h-8 w-8 flex justify-center items-center border-r border-b border-gray-300">
						<EditUser
							firstName={row?.first_name}
							lastName={row?.last_name}
							email={row?.email}
							mobileNumber={row?.mobile_number}
							role={row?.role_name[0]}
							id={row?.id}
							userPermissions={row?.permission_names}
							allPermissions={allPermissions?.data}
							subjectGroups={row?.assignedSubjectGroups?.map(
								(subject) => subject.id
							)}
						/>
					</span>

					<span className="h-8 w-8 flex justify-center items-center border-l border-b border-gray-300">
						<DeleteUser
							userId={row?.id}
							elementId={`element-${row?.id}`}
						/>
					</span>
				</>
			)}
		</div>
	);

	const loginsTemplate = (row) => <UserLogins logins={row?.logins} />;

	const createdAtTemplate = (row) => <span>{row?.created_at}</span>;

	const nameTemplate = (row) => (
		<p className="flex flex-col">
			<span>
				{row.first_name} {row.last_name}
			</span>
			<span className="px-2 py-1 rounded-full text-white bg-akgreener text-xs text-center">
				{row?.role_name[0]}
			</span>
		</p>
	);

	const [selectedUsers, setselectedUsers] = useState([]);

	const { mutateAsync: mutateBulkAction, isLoading: isActioning } =
		useBulkActionUsers();

	const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			users: [],
			action: "",
		},
		onSubmit: async (data) => {
			switch (data.action) {
				case "activate":
					await mutateBulkAction({
						action: "activate",
						users: [...data.users],
					});
					setFieldValue("users", []);
					setFieldValue("action", "");
					setselectedUsers([]);
					break;

				case "deactivate":
					await mutateBulkAction({
						action: "deactivate",
						users: [...data.users],
					});
					setFieldValue("users", []);
					setFieldValue("action", "");
					setselectedUsers([]);

					break;
				default:
					break;
			}
		},

		enableReinitialize: true,
	});

	if (isLoadingUsers || isLoadingPermissions || isLoadingProfile) {
		return <AdminPageLoader />;
	}

	return (
		<Admin2>
			<div className="mt-4 w-full flex justify-center">
				<UsersFilters filters={filters} />
			</div>

			<div className="flex justify-center w-full">
				<form
					onSubmit={handleSubmit}
					className="flex flex-col items-center "
					style={{ maxWidth: "90%" }}
				>
					{values?.users?.length > 0 && (
						<div className="mb-4 flex items-center justify-center gap-2 py-2 bg-gradient-to-b from-gray-100 to-white w-full">
							<p className="text-base">With selected:</p>

							<label
								htmlFor="activate"
								className="cursor-pointer px-3 py-1 flex items-center gap-1 border border-gray-300 rounded hover:border-2 hover:border-akgreener hover:text-akgreener"
							>
								Activate{" "}
								<input
									name="action"
									value="activate"
									onChange={handleChange}
									id="activate"
									type="radio"
								/>
							</label>

							<label
								htmlFor="deactivate"
								className="cursor-pointer px-3 py-1 flex items-center gap-1 border border-gray-300 rounded hover:border-2 hover:border-akgreener hover:text-akgreener"
							>
								Deactivate{" "}
								<input
									name="action"
									value="deactivate"
									onChange={handleChange}
									id="deactivate"
									type="radio"
								/>
							</label>

							{values.action && (
								<button
									type="submit"
									className="rounded-lg px-2 py-1 bg-akgreen text-white flex gap-2 items-center justify-center"
								>
									Execute{" "}
									{isActioning && (
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									)}
								</button>
							)}
						</div>
					)}

					<DataTable
						value={users}
						selection={selectedUsers}
						onSelectionChange={(e) => {
							setselectedUsers(e.value);
							setFieldValue("users", [...e.value?.map((user) => user?.id)]);
						}}
						tableClassName="font-sans text-sm"
						autoLayout
						dataKey="id"
					>
						<Column
							selectionMode="multiple"
							headerStyle={{ width: "3em" }}
						></Column>
						<Column
							header="Name"
							headerClassName="text-center"
							body={nameTemplate}
						/>
						<Column
							field="email"
							header="Email"
							sortable
						/>
						<Column
							header="Phone Number"
							headerClassName="text-center"
							bodyClassName="text-center"
							body={(row) =>
								row.mobile_number ? (
									<span>{row.mobile_number} </span>
								) : (
									<AiOutlineLine size={20} />
								)
							}
							sortable
						/>
						<Column
							header="Registered on"
							body={createdAtTemplate}
						/>
						<Column
							header="Logins"
							headerClassName="text-center"
							body={loginsTemplate}
						/>
						<Column
							header="Actions"
							headerClassName="text-center"
							body={actionBodyTemplate}
						/>
					</DataTable>

					<div className="flex-1 flex justify-end mt-6">
						<CustomPaginator
							firstPage={filters?.firstPage}
							setFirstPage={filters?.changeFirstPage}
							rowsPerPage={filters?.rowsPerPage}
							setRowsPerPage={filters?.changeRowsPerPage}
							totalRecords={totalRecords}
							setPage={filters?.changePage}
							showRowsPerPageDropdown={false}
						/>
					</div>

					<h4 className="my-4 flex items-center gap-1">
						<span className="text-3xl">{usersData?.data?.total_records}</span>{" "}
						users returned
					</h4>
				</form>
			</div>
		</Admin2>
	);
};

export default Users2;

import ProfileLayout from "../ProfileLayout";
import useSearchParams from "hooks/useSearchParams";
import useProfile from "api/UserResource/useProfile";
import useAuthStore from "store/authStore";
import UpdateInstitution from "../UpdateInstitution";
import { Role } from "utils/roles";

const MyInstitution = () => {
	const { getParam } = useSearchParams();
	const heading = getParam("heading") || "Institution details";

	const { hasRoles } = useAuthStore();

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	return (
		<ProfileLayout
			heading={heading}
			isLoading={isLoadingProfile}
			profile={profile?.data}
		>
			{hasRoles([Role.INSTITUTION]) && (
				<div>
					<UpdateInstitution
						institutionId={profile?.data?.institutions[0]?.id}
						name={profile?.data?.institutions[0]?.name}
						level={profile?.data?.institutions[0]?.level}
						address={profile?.data?.institutions[0]?.address}
						country={profile?.data?.institutions[0]?.country}
						province={profile?.data?.institutions[0]?.province}
						contactNumber={profile?.data?.institutions[0]?.contact_number}
					/>
				</div>
			)}
		</ProfileLayout>
	);
};

export default MyInstitution;

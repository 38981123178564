import useSearchParams from "hooks/useSearchParams";
import { Container } from "layouts";
import React from "react";

const SsoLogin = () => {
	const { getParam } = useSearchParams();
	const xReferenceId = getParam("x_reference_id");
	const email = getParam("email");

	const queryParams = React.useMemo(
		() => ({
			x_reference_id: xReferenceId,
			username: email,
		}),
		[xReferenceId, email]
	);

	console.log({ queryParams });

	// const { data, isLoading, isError, error } = useSSOLogin(
	// 	queryParams,
	// 	!!xReferenceId && !!email
	// );

	// if (isLoading) {
	// 	return (
	// 		<Container>
	// 			<main className="flex-1 flex flex-col justify-center items-center">
	// 				<AiOutlineLoading3Quarters
	// 					size={100}
	// 					className="animate-spin text-akgreen"
	// 				/>
	// 			</main>
	// 		</Container>
	// 	);
	// }

	return (
		<Container>
			<main className=" flex-1"></main>
		</Container>
	);
};

export default SsoLogin;

import useFetchInstitutions from "api/InstitutionResource/useFetchInstitutions";
import Admin2 from "layouts/Admin2";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect } from "react";
import useInstitutionsFilters from "./useInstitutionsFilters";
import ViewAdmin from "./ViewAdmin";
import { CustomPaginator } from "components";
import { delay } from "utils";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import ClearFilters from "./ClearFilters";

function CounterCircle({ count, borderColor = "border-gray-300" }) {
	return (
		<div
			className={`flex items-center justify-center w-12 h-12 rounded-full border-2 ${borderColor}`}
		>
			{count || 0}
		</div>
	);
}

const Institutions = () => {
	const filters = useInstitutionsFilters();

	const [searchTerm, setSearchTerm] = React.useState("");
	const [isSearching, setIsSearching] = React.useState(false);

	useEffect(() => {
		setSearchTerm(filters.searchTerm);
		return () => {
			setSearchTerm("");
		};
	}, [filters.searchTerm]);

	const countDefinedFilters = [filters.searchTerm].filter(
		(item) => item
	)?.length;

	const { data, isLoading } = useFetchInstitutions({
		page: filters.page,
		rows_per_page: filters.rowsPerPage,
		// first_page: filters.firstPage,
		search: filters.searchTerm,
	});

	const institutions = data?.data?.records || [];

	// console.table(institutions);

	const totalRecords = data?.data?.total_records;

	const contactTemplate = (row) => (
		<div className="flex flex-col">
			{row?.address && <span>{row?.address}</span>}
			{row?.contact_number && (
				<span className=" py-1 rounded-full text-akgreen ">
					{row?.contact_number}
				</span>
			)}
		</div>
	);

	const adminTemplate = (row) => {
		if (!row?.contactPersons || row?.contactPersons.length <= 0) {
			return "-";
		}
		const user = row?.contactPersons[0];
		return (
			<ViewAdmin
				elementId={`admin${row.id}`}
				firstName={user?.first_name}
				lastName={user?.last_name}
				email={user?.email}
				phone={user?.mobile_number}
			/>
		);
	};

	const handleChangeSearch = async (e) => {
		setSearchTerm(e.target.value);
		setIsSearching(true);
		await delay(5);
		setIsSearching(false);
		setSearchTerm(e.target.value);
		filters.changeSearchTerm(e.target.value);
	};

	// if (isLoading) {
	// 	return <AdminPageLoader />;
	// }
	return (
		<Admin2>
			<div className="mt-10 space-y-4">
				<div className="flex justify-between items-center ">
					<h3>Institutions list ({!isLoading ? totalRecords : "0"})</h3>
					<div className="flex justify-end items-center gap-2 relative">
						<select
							name="rowsPerPage"
							id="rowsPerPage"
							value={filters.rowsPerPage}
							onChange={(e) => filters.changeRowsPerPage(e.target.value)}
							className="border border-gray-300 bg-white rounded h-10 px-2"
						>
							<option value="2">2</option>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="50">50</option>
						</select>

						<input
							type="text"
							value={searchTerm}
							className="relative border border-gray-300 bg-white rounded h-10 px-2 w-56"
							placeholder="Search institution..."
							onChange={handleChangeSearch}
						/>
						<ClearFilters
							count={countDefinedFilters}
							changeSearchTerm={filters.changeSearchTerm}
						/>
						{(isSearching || isLoading) && (
							<AiOutlineLoading3Quarters
								size={20}
								className="animate-spin absolute "
								style={{
									top: "0.7rem",
									right: countDefinedFilters > 0 ? "9.5rem" : "0.7rem",
								}}
							/>
						)}
					</div>
				</div>

				<div className={`py-6 rounded index relative `}>
					<div
						className="absolute rounded bg-gray-200 opacity-75"
						style={{
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							zIndex: 100,
							display: isSearching || isLoading ? "block" : "none",
						}}
					/>

					<DataTable
						value={institutions}
						dataKey="id"
						tableClassName="font-sans text-sm "
						autoLayout
					>
						<Column
							header="Name"
							field="name"
						/>
						<Column
							header="Contact Details"
							body={contactTemplate}
						/>
						<Column
							header="Country"
							body={(row) => <span>{row?.country || "-"}</span>}
						/>
						<Column
							header="Level"
							body={(row) => <span>{row?.level || "-"}</span>}
						/>

						<Column
							header="Number of Students"
							body={(row) => (
								<CounterCircle
									count={row?.numberOfStudents}
									borderColor="border-akgreener"
								/>
							)}
						/>

						<Column
							header="Number of Educators"
							body={(row) => (
								<CounterCircle
									count={row?.numberOfEducators}
									borderColor="border-blue-600"
								/>
							)}
						/>
						<Column
							header="Action(s)"
							body={adminTemplate}
						/>
					</DataTable>
				</div>

				<div className="flex-1 flex items-end justify-end pb-4">
					<CustomPaginator
						firstPage={filters.firstPage}
						setFirstPage={filters.changeFirstPage}
						rowsPerPage={filters.rowsPerPage}
						setRowsPerPage={filters.changeRowsPerPage}
						totalRecords={totalRecords}
						setPage={filters.changePage}
						showRowsPerPageDropdown={false}
					/>
				</div>
			</div>
		</Admin2>
	);
};

export default Institutions;

import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";

const useChangeStatus = (publisherId) => {
	const { accessToken } = useTokenStore();
	const queryClient = useQueryClient();

	const changeStatus = async (status) => {
		const instance = await InitApi({ accessToken });
		const response = await instance.put(
			`publishers/change-status/${publisherId}`,
			{ status }
		);
		queryClient.invalidateQueries("publishers");
		return response?.data;
	};

	return useMutation(changeStatus, {
		onSuccess: async (data) => {
			toast.success(data.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useChangeStatus;

import useUpdateProfile from "api/UserResource/useUpdateProfile";
// import { AvatarInput } from "components";
import { Form, Formik } from "formik";
import { MAX_IMAGE_SIZE, phoneRegex } from "utils";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import moment from "moment";
import useSearchParams from "hooks/useSearchParams";

const UpdateProfile = ({
	userId,
	firstName,
	lastName,
	mobileNumber,
	dob,
	sex,
}) => {
	const { getParam } = useSearchParams();

	const returnUrl = getParam("returnUrl") || "";

	const initialValues = {
		first_name: firstName || "",
		last_name: lastName || "",
		mobile_number: mobileNumber || "",
		dob: dob || "",
		sex: sex || "",
		avatar: null,
	};

	const validateSchema = Yup.object().shape({
		first_name: Yup.string().required("First name is required"),
		last_name: Yup.string().required("Surname is required"),
		mobile_number: Yup.string()
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least 10 characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
		dob: Yup.string().matches(
			/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
			"Invalid date format"
		),
		avatar: Yup.mixed()
			.nullable()
			.test(
				"fileSize",
				`Image size should not exceed ${MAX_IMAGE_SIZE}MB`,
				(value) => {
					if (!value) return true;
					return value.size / 1024 / 1024 <= MAX_IMAGE_SIZE;
				}
			)
			.test(
				"fileMimeType",
				"Only png, jpg, or jpeg document is required",
				(value) => {
					if (!value) return true;
					return (
						value.type === "image/png" ||
						value.type === "image/jpeg" ||
						value.type === "image/svg+xml" ||
						value.type === "image/webp"
					);
				}
			),
	});

	const { mutateAsync, isLoading } = useUpdateProfile(userId, returnUrl);

	const handleUpdateProfile = async (data) => {
		await mutateAsync(data);
	};

	return (
		<>
			<div>
				<Formik
					initialValues={initialValues}
					validationSchema={validateSchema}
					onSubmit={(data) => handleUpdateProfile(data)}
				>
					{(formik) => (
						<Form className="space-y-8">
							<FormField
								name="first_name"
								label="First Name *"
							/>

							<FormField
								name="last_name"
								label="Last Name *"
							/>

							<FormField
								name="mobile_number"
								type="phone"
								label="Phone Number"
								placeholder="phone"
								value={formik.values.mobile_number}
								_onChange={(phone) => {
									if (phone !== "+") formik.values.mobile_number = "+" + phone;
									else formik.values.mobile_number = phone;
								}}
								error={Boolean(
									formik.errors.mobile_number && formik.touched.mobile_number
								)}
								errorText={formik.errors.mobile_number}
							/>

							<FormField
								name="dob"
								type="date"
								label="Date of Birth"
								value={formik.values.dob}
								onChange={(e) =>
									formik.setFieldValue(
										"dob",
										moment(e.target?.value).format("YYYY-MM-DD")
									)
								}
							/>

							<FormField
								type="select"
								name="sex"
								label="Select gender"
								options={[
									{ label: "Male", value: "Male" },
									{ label: "Female", value: "Female" },
								]}
							/>

							{/* <AvatarInput
								value={null}
								onChange={(event) =>
									formik.setFieldValue("avatar", event.target.files[0])
								}
								name="avatar"
							/> */}

							<div className="mt-4 flex items-center">
								<button
									type="submit"
									className="py-3 px-3 bg-akgreen w-full rounded-full text-white flex items-center justify-center gap-2 max-w-max "
								>
									Update profile
									{isLoading && (
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									)}
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};

export default UpdateProfile;

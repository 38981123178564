import useRegisterIndividual from "api/UserResource/useRegisterIndividual";
import { CheckBox } from "components";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { MAX_IMAGE_SIZE, phoneRegex } from "utils";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import moment from "moment";
import { createUsername } from "./register_utils";

const Individual = () => {
	const [captchaValue, setCaptchaValue] = useState("");
	const captchaHasValue = captchaValue?.length > 0;
	const initialValues = {
		email: "",
		username: "",
		mobile_number: "",
		password: "",
		password_confirmation: "",
		first_name: "",
		last_name: "",
		dob: "",
		sex: "",
		like_notifications: false,
		terms: false,
		avatar: null,
		role: "Individual",
	};

	const registerSchema = Yup.object().shape({
		username: Yup.string().required("Username is required"),
		email: Yup.string()
			.required("Email is required")
			.email("Invalid email address"),
		terms: Yup.boolean().isTrue(
			"Verify that you have read the terms and conditions"
		),
		mobile_number: Yup.string()
			.required("Mobile number is required")
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
		password: Yup.string()
			.min(6, "At least 6 characters required")
			.matches(/[a-zA-Z]/, "Must have at least one alphabet character")
			.matches(/[0-9]/, "Must have at least one digit")
			.required("Password required"),
		password_confirmation: Yup.string()
			.oneOf([Yup.ref("password")], "Passwords didn't match")
			.required("Confirmation required"),
		first_name: Yup.string().required("First name is required"),
		last_name: Yup.string().required("Surname is required"),
		sex: Yup.string().required("Gender is required"),
		dob: Yup.string().matches(
			/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
			"Invalid date format"
		),
		avatar: Yup.mixed()
			.nullable()
			.test(
				"fileSize",
				`Image size should not exceed ${MAX_IMAGE_SIZE}MB`,
				(value) => {
					if (!value) return true;
					return value.size / 1024 / 1024 <= MAX_IMAGE_SIZE;
				}
			),
	});

	const { mutateAsync, isLoading } = useRegisterIndividual();
	const handleRegister = async (data) => {
		const emailPrefix = `${data.first_name}.${data.last_name}`;
		const emailAddress =
			data.email?.length > 0
				? data.email
				: `${createUsername(emailPrefix)}@gmail.com`;
		await mutateAsync({
			...data,
			email: emailAddress,
			like_notifications: data.like_notifications ? 1 : 0,
			captcha: captchaValue,
			client: "web",
		});
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(data) => {
				handleRegister(data);
			}}
			validationSchema={registerSchema}
		>
			{(formik) => {
				function generateUsernameUsingName(e) {
					const inputIsName =
						e.target.name === "first_name" || e.target.name === "last_name";
					if (inputIsName) {
						let username;
						if (e.target.name === "first_name") {
							username = `${e.target.value}.${formik.values.last_name}`;
						} else {
							username = `${formik.values.first_name}.${e.target.value}`;
						}
						return formik.setFieldValue("username", createUsername(username));
					}
				}

				return (
					<Form
						className="flex flex-col w-full"
						id="captchaId"
					>
						<h4 className="uppercase font-cera-black">Personal Information</h4>

						<div className="grid grid-cols-3 md:grid-cols-6 items-start gap-2 mt-6">
							<div className="col-span-3 w-full">
								<FormField
									name="first_name"
									type="text"
									label="Name*"
									onInput={(e) => generateUsernameUsingName(e)}
								/>
							</div>

							<div className="col-span-3 mt-4 md:mt-0">
								<FormField
									name="last_name"
									type="text"
									label="Surname*"
									onInput={(e) => generateUsernameUsingName(e)}
								/>
							</div>
						</div>
						<div className="grid grid-cols-3 md:grid-cols-6 items-start gap-2 mt-6">
							<div className="col-span-3">
								<FormField
									name="username"
									type="text"
									label="Username*"
									helperText={
										<p className="flex flex-col">
											<span>If you already have a Smart Learning account,</span>
											<span>use can use your Smart Learning username.</span>
										</p>
									}
								/>
							</div>
							<div className="col-span-3 mt-4 md:mt-0">
								<FormField
									name="email"
									type="text"
									label="Email*"
								/>
							</div>
						</div>
						<div className="w-full mt-6">
							<FormField
								name="mobile_number"
								type="phone"
								label="Phone Number*"
								value={formik.values.mobile_number}
								_onChange={(phone) => {
									if (phone !== "+") formik.values.mobile_number = "+" + phone;
									else formik.values.mobile_number = phone;
								}}
								error={Boolean(
									formik.errors.mobile_number && formik.touched.mobile_number
								)}
								errorText={formik.errors.mobile_number}
								// width="w-40"
							/>
						</div>
						<div className="grid grid-cols-3 md:grid-cols-6 items-start gap-2 mt-6">
							<div className="col-span-3">
								<FormField
									name="dob"
									type="date"
									label="Date of Birth"
									value={formik.values.dob}
									onChange={(e) =>
										formik.setFieldValue(
											"dob",
											moment(e.target?.value).format("YYYY-MM-DD")
										)
									}
								/>
							</div>
							<div className="col-span-3 ">
								<FormField
									type="select"
									name="sex"
									label="Select gender"
									options={[
										{ label: "Male", value: "Male" },
										{ label: "Female", value: "Female" },
									]}
								/>
							</div>
						</div>
						<div className="grid grid-cols-3 md:grid-cols-6 items-start gap-2 mt-6">
							<div className="col-span-3">
								<FormField
									name="password"
									type="password"
									label="Password*"
									helperText={
										<p className="flex flex-col">
											<span>At least 6 characters are required </span>
											<span>
												Must have at least one alphabet (A-Z) character
											</span>
											<span>Must have at least one digit (0-9)</span>
										</p>
									}
								/>
							</div>
							<div className="col-span-3 mt-4 md:mt-0">
								<FormField
									name="password_confirmation"
									type="password"
									label="Confirm Password*"
								/>
							</div>
						</div>
						<div className="mt-6">
							<CheckBox
								name="terms"
								label={
									<span className="text-sm">
										I have read the{" "}
										<Link
											to="/terms-and-conditions"
											className="font-semibold"
										>
											terms and conditions
										</Link>
									</span>
								}
							/>
						</div>

						<div className="mt-6">
							<ReCAPTCHA
								sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
								onChange={(val) => setCaptchaValue(val)}
							/>
						</div>

						<div className="mt-6">
							<button
								type="submit"
								className={`px-4 py-2 flex items-center justify-center gap-2 text-white rounded-full text-base ${
									captchaHasValue
										? "bg-gradient-to-r from-akgreen to-akgreenest"
										: "bg-gray-200 cursor-not-allowed text-gray-500"
								}`}
								disabled={!captchaHasValue}
							>
								Create account
								{isLoading ? (
									<AiOutlineLoading3Quarters
										size={25}
										className="animate-spin"
									/>
								) : undefined}
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default Individual;

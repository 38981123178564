import Admin2 from "layouts/Admin2";
import CreateVoucher from "./CreateVoucher";
import useGetActiveVouchers from "api/VoucherResource/useGetActiveVouchers";
import { ButtonLoader } from "components";
import { getActiveCurrencyCodeLabel } from "utils";

const Vouchers = () => {
	const { data, isLoading } = useGetActiveVouchers();

	return (
		<Admin2>
			<div className="grid grid-cols-12 gap-x-8 gap-y-4">
				<div className="col-span-12">
					<CreateVoucher />
				</div>

				<div className="col-span-12">
					{isLoading ? (
						<ButtonLoader />
					) : (
						<div className="space-y-2">
							<h4 className="font-bold">Available vouchers</h4>

							<div className="grid grid-cols-3 gap-4">
								{data?.map((voucher) => (
									<div
										key={voucher.id}
										className="col-span-1 py-6 px-4 bg-gray-200 rounded-lg"
									>
										<div className="flex items-center gap-2 p-2 bg-gray-100 rounded-lg shadow-sm">
											{/* <span className="text-base text-gray-500">Label</span> */}
											<p className={`font-semibold text-base text-gray-600 `}>
												{voucher?.tag ?? (
													<span className="italic font-thin text-gray-400">
														Unnamed
													</span>
												)}
											</p>
										</div>

										<div className="flex items-center gap-2 mt-3">
											<span>Voucher number:</span>
											<p className="font-semibold">{voucher.voucher_number}</p>
										</div>

										<div className="flex items-center gap-2">
											<span>Value on creation:</span>
											<p className="font-semibold">
												{getActiveCurrencyCodeLabel(voucher.currency)}${" "}
												{voucher.number_of_tokens}
											</p>
										</div>

										<div className="flex items-center gap-2">
											<span>Current value:</span>
											<p className="font-semibold">
												{getActiveCurrencyCodeLabel(voucher.currency)}${" "}
												{voucher.remaining}
											</p>
										</div>

										<div className="flex items-center gap-2">
											<span>Expiry date:</span>
											<p className="font-semibold">{voucher.expiry_date}</p>
										</div>

										<div className="pt-3">
											{voucher?.is_hlf ? (
												<span className="h-8 w-28 flex items-center justify-center rounded-full border border-akgreen text-akgreener font-bold">
													HLF voucher
												</span>
											) : (
												<span className=" h-8 flex items-center bg-transparent "></span>
											)}
										</div>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</Admin2>
	);
};

export default Vouchers;

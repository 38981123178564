/**
 * @description Constants for the application
 */
//------------------------------------------------------------------------------

/**
 * Publisher approval statuses
 * ---------------------------
 */
export const PublisherApprovalStatus = Object.freeze({
	Approved: "approved",
	Rejected: "rejected",
	Pending: "pending",
});

import useRegisterInstitution from "api/UserResource/useRegisterInstitution";
import { CheckBox } from "components";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { MAX_IMAGE_SIZE, phoneRegex } from "utils";
import * as Yup from "yup";
import { countries, institutionLevels } from "utils/data";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { createUsername } from "./register_utils";
import useGetProvinces from "api/Province/useGetProvinces";

const Institution = () => {
	const [captchaValue, setCaptchaValue] = useState("");
	const recaptchaRef = useRef(null);

	const captchaHasValue = captchaValue?.length > 0;

	const { data: provinces, isLoading: isLoadingProvinces } = useGetProvinces();

	// console.log({ provinces });

	const initialValues = {
		email: "",
		username: "",
		password: "",
		first_name: "",
		last_name: "",
		sex: "",
		mobile_number: "",
		password_confirmation: "",
		name: "",
		type: "School",
		level: "",
		country: "Zimbabwe",
		province: "",
		number_of_users: "",
		like_notifications: false,
		terms: false,
		avatar: null,
		role: "Institution",
	};

	const registerSchema = Yup.object().shape({
		username: Yup.string().required("Username is required"),
		email: Yup.string()
			.required("Email is required")
			.email("Invalid email address"),
		terms: Yup.boolean().isTrue(
			"Verify that you have read the terms and conditions"
		),
		mobile_number: Yup.string()
			.required("Mobile number required")
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
		password: Yup.string()
			.min(6, "At least 6 characters required")
			.matches(/[a-zA-Z]/, "Must have at least one alphabet character")
			.matches(/[0-9]/, "Must have at least one digit")
			.required("Password required"),
		password_confirmation: Yup.string()
			.oneOf([Yup.ref("password")], "Passwords didn't match")
			.required("Confirmation required"),
		first_name: Yup.string().required("First name is required"),
		last_name: Yup.string().required("Last name is required"),
		name: Yup.string().required("Name of institution is required"),
		level: Yup.string().required("Level of institution is required"),
		country: Yup.string().required(
			"Provide country where institution is located"
		),
		province: Yup.string().when("country", {
			is: (country) => country === "Zimbabwe",
			then: Yup.string().required("Province is required"),
		}),
		avatar: Yup.mixed()
			.nullable()
			.test(
				"fileSize",
				`Image size should not exceed ${MAX_IMAGE_SIZE}MB`,
				(value) => {
					if (!value) return true;
					return value.size / 1024 / 1024 <= MAX_IMAGE_SIZE;
				}
			),
	});

	const { mutateAsync, isLoading } = useRegisterInstitution();
	const handleRegister = async (data) => {
		try {
			await mutateAsync({
				...data,
				type: "School",
				like_notifications: data.like_notifications ? 1 : 0,
				captcha: captchaValue,
				client: "web",
			});
		} catch (error) {
			if (recaptchaRef.current) recaptchaRef.current.reset();
			setCaptchaValue(""); // Clear captcha value
			// console.error("Registration failed:", error);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(data) => handleRegister(data)}
			validationSchema={registerSchema}
		>
			{(formik) => {
				function generateUsernameUsingName(e) {
					const inputIsName =
						e.target.name === "first_name" || e.target.name === "last_name";
					if (inputIsName) {
						let username;
						if (e.target.name === "first_name") {
							username = `${e.target.value}.${formik.values.last_name}`;
						} else {
							username = `${formik.values.first_name}.${e.target.value}`;
						}
						return formik.setFieldValue("username", createUsername(username));
					}
				}
				return (
					<Form className="flex flex-col w-full">
						<h4 className="uppercase font-cera-black">Personal Information</h4>

						<div className="grid grid-cols-3 md:grid-cols-6 items-start gap-2 mt-6">
							<div className="col-span-3">
								<FormField
									name="first_name"
									type="text"
									label="Name*"
									onInput={(e) => generateUsernameUsingName(e)}
								/>
							</div>

							<div className="col-span-3 ">
								<FormField
									name="last_name"
									type="text"
									label="Surname*"
									onInput={(e) => generateUsernameUsingName(e)}
								/>
							</div>
						</div>

						<div className="grid grid-cols-3 md:grid-cols-6 items-start gap-2 mt-6">
							<div className="col-span-3">
								<FormField
									name="username"
									type="text"
									label="Username*"
								/>
							</div>
							<div className="col-span-3">
								<FormField
									name="email"
									type="text"
									label="Email*"
								/>
							</div>
						</div>

						<div className="w-full mt-6">
							<div className="w-full">
								<FormField
									name="mobile_number"
									type="phone"
									label="Phone Number*"
									value={formik.values.mobile_number}
									_onChange={(phone) => {
										if (phone !== "+")
											formik.values.mobile_number = "+" + phone;
										else formik.values.mobile_number = phone;
									}}
									error={Boolean(
										formik.errors.mobile_number && formik.touched.mobile_number
									)}
									errorText={formik.errors.mobile_number}
								/>
							</div>
						</div>

						<div className="grid grid-cols-3 md:grid-cols-6 items-start gap-2 mt-6">
							<div className="col-span-3">
								<FormField
									name="password"
									type="password"
									label="Password*"
								/>
							</div>
							<div className="col-span-3 mt-4 md:mt-0">
								<FormField
									name="password_confirmation"
									type="password"
									label="Confirm Password*"
								/>
							</div>
						</div>

						<h4 className="uppercase font-cera-black mt-6">
							Institution Information
						</h4>

						<div className="grid grid-cols-2 md:grid-cols-6 items-start gap-2 mt-6">
							<div className="col-span-3">
								<FormField
									name="name"
									type="text"
									label="Name of Institution*"
								/>
							</div>
							<div className="col-span-3">
								<FormField
									type="select"
									name="level"
									label="Institution Level*"
									options={institutionLevels.map((level) => ({
										label: level.name,
										value: level.value,
									}))}
								/>
							</div>
						</div>

						<div className="grid grid-cols-2 md:grid-cols-6 items-start gap-2 mt-6">
							<div
								className={`${
									formik.values.country === "Zimbabwe"
										? "col-span-3"
										: "col-span-6"
								}`}
							>
								<FormField
									type="select"
									name="country"
									label="Country*"
									options={countries.map((country) => ({
										label: country.name,
										value: country.name,
									}))}
								/>
							</div>

							{formik.values.country === "Zimbabwe" && (
								<div className="col-span-3">
									<FormField
										type="select"
										name="province"
										label="Province"
										isLoading={isLoadingProvinces}
										options={
											provinces?.map((province) => ({
												label: province.name,
												value: province.name,
											})) || []
										}
									/>
								</div>
							)}
						</div>

						<div className="mt-6">
							<CheckBox
								name="terms"
								label={
									<span className="text-sm">
										I have read the{" "}
										<Link
											to="/terms-and-conditions"
											className="font-semibold"
										>
											terms and conditions
										</Link>
									</span>
								}
							/>
						</div>

						<div className="mt-6">
							<ReCAPTCHA
								ref={recaptchaRef}
								sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
								onChange={(val) => setCaptchaValue(val)}
							/>
						</div>

						<div className="mt-6">
							<button
								type="submit"
								className={`px-4 py-2 flex items-center justify-center gap-2 text-white rounded-full text-base ${
									captchaHasValue
										? "bg-gradient-to-r from-akgreen to-akgreenest"
										: "bg-gray-200 cursor-not-allowed text-gray-500"
								}`}
								disabled={!captchaHasValue}
							>
								Create account
								{isLoading ? (
									<AiOutlineLoading3Quarters
										size={25}
										className="animate-spin"
									/>
								) : undefined}
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default Institution;

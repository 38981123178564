/**
 * @typedef {Object} Publisher
 * @property {string} id
 * @property {string} name
 * @property {string} country
 * @property {string} physical_address
 *
 * @param {Publisher[]} publisher
 *
 *
 */
export function isPublisherProfileComplete(publisher) {
	if (!publisher) return false;
	if (publisher.length <= 0) return false;
	if (!publisher[0].name || !publisher[0].country) return false;

	return true;
}

export const TIME_TO_REFETCH = 10000;

import useSearchParams from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { deleteEmptyObjects } from "utils";

const useUsersFilters = () => {
	const [firstPage, setFirstPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [page, setPage] = useState(1);
	const [roleIs, setRoleIs] = useState("");
	const [trashed, setTrashed] = useState("with");
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [dateFrom, setDateFrom] = useState("");
	const [dateTo, setDateTo] = useState("");
	const [search, setSearch] = useState("");

	const { getParam, setParam, deleteParam, hasParam } = useSearchParams();
	const { push } = useHistory();
	const { pathname } = useLocation();

	useEffect(() => {
		if (hasParam("page")) {
			const param = getParam("page")?.length > 0 ? getParam("page") : 1;
			setPage(param);
		}
		if (hasParam("rows")) {
			const param = getParam("rows")?.length > 0 ? getParam("rows") : 10;
			setRowsPerPage(param);
		}
		if (hasParam("date_from")) {
			const param =
				getParam("date_from")?.length > 0 ? getParam("date_from") : 0;
			setDateFrom(param);
		}
		if (hasParam("date_to")) {
			const param = getParam("date_to")?.length > 0 ? getParam("date_to") : 0;
			setDateTo(param);
		}
		if (hasParam("first_page")) {
			const param =
				getParam("first_page")?.length > 0 ? getParam("first_page") : 0;
			setFirstPage(param);
		}
		if (hasParam("role_is")) {
			const param = getParam("role_is")?.length > 0 ? getParam("role_is") : "";
			setRoleIs(param);
		}
		if (hasParam("trashed")) {
			const param = getParam("trashed")?.length > 0 ? getParam("trashed") : "";
			setTrashed(param);
		}
		if (hasParam("email")) {
			const param = getParam("email")?.length > 0 ? getParam("email") : "";
			setEmail(param);
		}
		if (hasParam("first_name")) {
			const param =
				getParam("first_name")?.length > 0 ? getParam("first_name") : "";
			setFirstName(param);
		}
		if (hasParam("last_name")) {
			const param =
				getParam("last_name")?.length > 0 ? getParam("last_name") : "";
			setLastName(param);
		}
		if (hasParam("search")) {
			const param = getParam("search")?.length > 0 ? getParam("search") : "";
			setSearch(param);
		}

		return () => {
			deleteParam("date_from");
			deleteParam("date_to");
			deleteParam("page");
			deleteParam("rows");
			deleteParam("first_page");
			deleteParam("role_is");
			deleteParam("trashed");
			deleteParam("email");
			deleteParam("first_name");
			deleteParam("last_name");
			deleteParam("search");
		};
	}, [hasParam, getParam, deleteParam]);

	const changePage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("page");
			setPage(1);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("page", value);
		push(`${pathname}?${q}`);
	};

	const changeRowsPerPage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("rows");
			setRowsPerPage(10);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("rows", value);
		push(`${pathname}?${q}`);
	};

	const changeFirstPage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("first_page");
			setFirstPage(0);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("first_page", value);
		push(`${pathname}?${q}`);
	};

	const changeRoleIs = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("role_is");
			setRoleIs("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("role_is", value);
		push(`${pathname}?${q}`);
	};

	const changeTrashed = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("trashed");
			setTrashed("with");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("trashed", value);
		push(`${pathname}?${q}`);
	};

	const changeEmail = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("email");
			setEmail("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("email", value);
		push(`${pathname}?${q}`);
	};

	const changeFirstName = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("first_name");
			setFirstName("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("first_name", value);
		push(`${pathname}?${q}`);
	};

	const changeLastName = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("last_name");
			setLastName("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("last_name", value);
		push(`${pathname}?${q}`);
	};

	const changeSearch = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("search");
			setSearch("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("search", value);
		push(`${pathname}?${q}`);
	};

	const changeDateFrom = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("date_from");
			setDateFrom("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("date_from", value);
		push(`${pathname}?${q}`);
	};

	const changeDateTo = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("date_to");
			setDateTo("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("date_to", value);
		push(`${pathname}?${q}`);
	};

	const changeAllFiltersAtOnce = (data) => {
		const values = deleteEmptyObjects(data);
		setFirstPage(values?.first_page || "");
		setRowsPerPage(values?.rows || "");
		setPage(values?.page || "");
		setRoleIs(values?.role_is || "");
		setTrashed(values?.trashed || "");
		setEmail(values?.email || "");
		setFirstName(values?.first_name || "");
		setLastName(values?.last_name || "");
		setSearch(values?.search || "");
		setDateFrom(values?.date_from || "");
		setDateTo(values?.date_to || "");
		push(`${pathname}?${new URLSearchParams(values).toString()}`);
	};

	return {
		firstPage,
		rowsPerPage,
		page,
		roleIs,
		trashed,
		email,
		firstName,
		lastName,
		dateFrom,
		dateTo,
		search,
		changeDateFrom,
		changeDateTo,
		changePage,
		changeRowsPerPage,
		changeFirstPage,
		changeRoleIs,
		changeTrashed,
		changeEmail,
		changeFirstName,
		changeLastName,
		changeSearch,
		changeAllFiltersAtOnce,
	};
};

export default useUsersFilters;

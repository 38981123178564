import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import useCreateVoucher from "api/VoucherResource/useCreateVoucher";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { activeCurrencies, getActiveCurrencyCodeLabel } from "utils";
import moment from "moment";
import { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { BiChevronRight } from "react-icons/bi";

const CreateVoucher = () => {
	const today = new Date().toISOString().split("T")[0];
	const [visible, setVisible] = useState(false);

	const initialValues = {
		number_of_tokens: "",
		expiry_date: "",
		is_hlf: false,
		tag: "",
		currency: activeCurrencies.USD.code,
	};

	const validate = Yup.object({
		number_of_tokens: Yup.number()
			.required("Enter number of tokens")
			.min(1, "Number of tokens must be greater than 0"),
		expiry_date: Yup.string().required("Expiry date is required"),
		// is_hlf: Yup.string().required("Select if voucher is HLF or not"),
		is_hlf: Yup.boolean().required("Select if voucher is HLF or not"),
	});

	const { mutateAsync, isLoading } = useCreateVoucher();

	const handleSubmit = (data) => {
		// return console.log(data);
		mutateAsync(data);
		setVisible(false);
	};

	return (
		<>
			<button
				onClick={() => setVisible(true)}
				className="rounded-full px-4 py-2 border-2 border-akgreen text-akgreenest flex items-center hover:text-white hover:bg-akgreener transform duration-150 "
			>
				Create a new voucher
				<BiChevronRight size={25} />
			</button>

			<Sidebar
				visible={visible}
				onHide={() => setVisible(false)}
				position="right"
				style={{
					width: "100%",
					maxWidth: "500px",
				}}
			>
				<div className="space-y-8">
					<div>
						<h3>Please fill the form below to create a voucher</h3>
						<p>Required fields are marked with a star (*)</p>
					</div>

					<div>
						<Formik
							initialValues={initialValues}
							validationSchema={validate}
							onSubmit={handleSubmit}
						>
							{(formik) => (
								<Form
									className="space-y-7"
									// style={{
									// 	width: 500,
									// }}
								>
									<FormField
										type="select"
										name="currency"
										label="Select currency for the voucher *"
										options={[
											{
												label: activeCurrencies.USD.label,
												value: activeCurrencies.USD.code,
											},
											{
												label: activeCurrencies.ZWL.label,
												value: activeCurrencies.ZWL.code,
											},
										]}
									/>

									<FormField
										label={`Number of tokens * (1 token = 1 ${getActiveCurrencyCodeLabel(
											formik.values.currency
										)})`}
										name="number_of_tokens"
										type="number"
									/>

									<FormField
										label="Expiry date *"
										name="expiry_date"
										type="date"
										value={formik.values.expiry_date}
										onChange={(e) =>
											formik.setFieldValue(
												"expiry_date",
												moment(e.target.value).format("YYYY-MM-DD")
											)
										}
									/>

									<FormField
										label={`Label associated with the voucher (e.g HLF ${today})`}
										name="tag"
									/>

									<div className="p-4 rounded-lg bg-gray-100">
										<p className="text-base font-bold">
											Is this an HLF voucher?
										</p>
										<FormField
											type="switcher"
											name="is_hlf"
											label="Turn on if this is an HLF voucher?"
											switchLabelPlacement="left"
										/>
									</div>

									<button
										type="submit"
										className="px-4 py-2 bg-akgreenest text-white rounded-lg flex items-center gap-1"
									>
										{isLoading ? (
											<>
												Submitting{" "}
												<AiOutlineLoading3Quarters
													size={20}
													className="animate-spin"
												/>
											</>
										) : (
											<>
												Submit <IoIosArrowForward size={20} />
											</>
										)}
									</button>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</Sidebar>
		</>
	);
};

export default CreateVoucher;

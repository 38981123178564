import useFetchReadReport from "api/AdminReports/useFetchReadReport";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { BiCalendar } from "react-icons/bi";

const ReadReport = () => {
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");

	const { data: reads } = useFetchReadReport({
		from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
		to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
	});
	const readers = reads?.data?.top_readers || [];

	const tableRowNumber = (row) => {
		if (!readers) return null;
		const index = readers.indexOf(row);
		const rowNumber = index + 1;
		return (
			<span className="h-9 w-9 flex justify-center items-center rounded-full border border-akgreener text-akgreenest font-bold text-lg">
				{rowNumber}
			</span>
		);
	};

	return (
		<div className="w-full space-y-4">
			<div className="flex justify-between items-end w-full ">
				<h4 className="font-cera-black">Readers report</h4>

				<div className="flex gap-4 items-center">
					<span className="p-float-label">
						<Calendar
							id={fromDate}
							className="font-sans"
							value={fromDate}
							onChange={(e) => setFromDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={fromDate}>From date -</label>
					</span>

					<span className="p-float-label">
						<Calendar
							id={toDate}
							className="font-sans"
							value={toDate}
							onChange={(e) => setToDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={toDate}>To date -</label>
					</span>
				</div>
			</div>

			<div>
				<div className="flex items-center gap-2">
					<span className="font-bold text-xl ">
						{reads?.data?.readers_count || 0}
					</span>
					<span className="text-base">
						people have been reading on the platform
					</span>
				</div>

				{reads?.data?.readers_count > 0 && (
					<div className="space-y-4">
						<h4 className="text-base">
							Here are the top {reads?.data?.ranking_limit} readers
						</h4>

						<DataTable
							value={readers}
							tableClassName="font-sans text-sm"
							autoLayout
							dataKey="id"
						>
							<Column
								header="#"
								headerClassName="text-xl"
								body={tableRowNumber}
							/>

							<Column
								header="Name"
								body={(row) => (
									<div className="flex items-center gap-1">
										<span>{row?.first_name}</span>
										<span>{row?.last_name}</span>
									</div>
								)}
							/>

							<Column
								field="email"
								header="Email"
							/>

							<Column
								body={(row) => <span>{row?.total_read_duration} minutes</span>}
								header="Minutes spend"
							/>
						</DataTable>
					</div>
				)}
			</div>
		</div>
	);
};

export default ReadReport;
